export default {
  id: '47b7d8ea1e',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <!-- Scrollable page content -->\n  <div data-page="finShareFollowers" class="page" id="finShareFollowers_div">\n    <div class="navbar">\n      <div class="navbar-bg"></div>\n      <div class="navbar-inner">\n        <div class="left link">\n          <a href="/mySettings/" class="link"\n            ><i class="icon f7-icons">chevron_left</i><span>Back</span></a\n          >\n        </div>\n        <div class="title center sliding">finShare Followers</div>\n        <div class="right link">\n          <a href="/finshare/overview/" class="link"\n            ><i class="icon f7-icons">gear_alt</i></a\n          >\n        </div>\n      </div>\n\n      <!-- Subnavbar -->\n      <div class="subnavbar">\n        <div class="subnavbar-inner">\n          <!-- Subnavbar content, for example tabs buttons -->\n          <div class="segmented">\n            <a href="/finshare/" class="button">My Group</a>\n            <a href="/finshare/followers/" class="button button-active"\n              >Followers <span class="fs-req-count"></span\n            ></a>\n          </div>\n        </div>\n      </div>\n    </div>\n\n    <!-- Scrollable page content -->\n    <div class="page-content">\n      <div class="block"></div>\n      <!--  END CONTENT BLOCK -->\n\n      <div><h3 class="margin-left_1em margin-right_1em">Requesting</h3></div>\n      <div class="list" id="fs_requested"></div>\n\n      <div><h3 class="margin-left_1em margin-right_1em">Approved</h3></div>\n      <div class="list" id="fs_approved"></div>\n    </div>\n    <!-- END PAGE CONTENT -->\n  </div>\n  <!-- END PAGE -->\n';
      return r;
    }(this);
  },
  styleScoped: false
};